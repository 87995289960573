<template>
  <div>
    <b-form-group
        v-if="basePropElems.js.actions[actionObj.type].child.widgets === 'getElemWithAnimate'"
        label-cols-sm="4"
        :label="$t('General.Base.Element')"
        label-size="sm"
        class="mb-1"
    >
<!--      <b-input-group-->
<!--          -->
<!--          size="sm"-->
<!--      >-->

        <multiselect
            :placeholder="$t('General.Form.NoSet')"
            v-model="actionObj.data.elemId"
            track-by="value"
            label="text"
            :options="getAllElements()"
            class="mousetrap multiselect-sm form-control"
            :close-on-select="false"
            multiple
            :show-labels="false"
        ></multiselect>

<!--        <b-form-select-->
<!--            :options="getAllElements()"-->
<!--            v-model="actionObj.data.elemId"-->
<!--            multiple-->
<!--        ></b-form-select>-->
<!--      </b-input-group>-->
    </b-form-group>

    <AnimateWidget v-model="actionObj.data.animate" />

  </div>
</template>

<script>
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import AnimateWidget from "./../../helpers/AnimateWidget";
import Multiselect from "vue-multiselect";

export default {
  name: "GetElemWithAnimate",
  components: { AnimateWidget, Multiselect },
  props: ["tagData", "settingConstructor", "basePropElems", "actionObj"],
  data() {
    return {
      dataPattern: {
        elemId: null,
        animate: null
      }
    }
  },
  created() {
    if (this.actionObj.data === null) {
      this.actionObj.data = this.dataPattern
    }
  },
  methods: {
    ...TagDataGetElemsHelper,
    getAllElements() {
      // if (this.actionObj.data.elemId !== null) {
      //   return this.getAllElementsToOptions()
      // }
      return [ ...this.getAllElementsToOptions()] //...[{value: null, text: this.$t('General.Base.SelectItem')}],
    }
  }
}
</script>
